<template>

  <table class="table table-bordered">
    <thead>
    <tr>
      <th scope="col">Тег</th>
      <th scope="col">Ціна</th>
      <th scope="col">SKUs</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in conditions" :key="item">
      <td :class="'lvl-'+item.lvl+ ' has-child-'+item.hasChild" class="item">
        <div>{{ item.name }}</div>
        <div class="action">
          <button v-if="item.canEdit" class="edit" @click="edit(item)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil"
                 viewBox="0 0 16 16">
              <path
                  d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
            </svg>
          </button>
        </div>
      </td>
      <td>

        <div class="price-block" v-if="!item.hasChild && item.path">
          <input type="text" class="form-control form-control-sm" v-model="item.price.from" placeholder="від"
                 @change="emitTree">
          <input type="text" class="form-control form-control-sm" v-model="item.price.to" placeholder="до"
                 @change="emitTree">
        </div>

      </td>
      <td>
        <div class="form-check" v-if="!item.hasChild && item.path">
          <input class="form-check-input" type="checkbox" id="invertSku" v-model="item.invert" @change="emitTree">
          <label class="form-check-label" for="invertSku">
            Інвертувати
          </label>
        </div>
        <textarea v-if="!item.hasChild && item.path" type="text" class="form-control form-control-sm"
                  v-model="item.skus"
                  @change="emitTree"
                  placeholder="Наприклад: U2210, P0001 ..."/>
      </td>
    </tr>
    </tbody>
  </table>

  <condition-modal v-model="editChildren" v-if="conditionModalShow" @close="updateTree"/>
</template>

<script>
import '@vuepic/vue-datepicker/dist/main.css';
import ConditionModal from "@/views/marketing/promo/ConditionModal.vue";

export default {
  name: "ConditionTable",
  components: {
    ConditionModal
  },
  props: {
    modelValue: Array,
  },
  emits: ['update', 'update:modelValue'],
  watch: {
    modelValue() {
      if (this.modelValue)
        this.conditions = this.createTree(this.modelValue);
    }
  },
  data() {
    return {
      conditionModalShow: false,
      conditions: this.createTree(this.modelValue),
      editItem: null,
      editChildren: []
    }
  },
  methods: {

    edit: function (item) {
      this.editItem = item;
      this.editChildren = this.getChildren(item);
      this.conditionModalShow = true;
    },

    getChildren: function (item) {
      if (!item.path)
        return this.conditions.filter(e => e.lvl === 1);

      return this.conditions
          .filter(e => e.path && e.path.startsWith(item.path) && e.lvl === item.lvl + 1);
    },

    updateTree: function () {

      let map = new Map();

      let treeChildren = this.getChildren(this.editItem);

      for (let item of treeChildren) {
        map.set(item.id, true);
      }

      for (let tag of this.editChildren) {
        if (map.has(tag.id))
          continue;

        this.addTreeItem(this.editItem, tag);
      }

      map = new Map();
      for (let tag of this.editChildren) {
        map.set(tag.id, true);
      }

      for (let item of treeChildren) {
        if (map.has(item.id))
          continue;

        this.removeTreeItem(item);
      }


      this.emitTree();

      this.conditionModalShow = false;
    },

    removeTreeItem: function (item) {
      this.conditions = this.conditions.filter(e => !e.path || !e.path.startsWith(item.path));

      for (let condition of this.conditions) {

        if (!condition.path) {
          condition.hasChild = this.conditions.length > 1;
          continue;
        }

        condition.hasChild = this.conditions.some(e => e.path && e.path !== condition.path && e.path.startsWith(condition.path))
      }

    },

    addTreeItem: function (item, tag) {

      this.conditions.push({
        id: tag.id,
        path: item.path ? item.path + ":" + tag.id : tag.id,
        name: tag.label,
        lvl: item.lvl * 1 + 1,
        price: null,
        skus: null,
        invert: false,
        hasChild: false,
        canEdit: tag.id.startsWith("category"),
      })

      item.hasChild = true;
      item.canEdit = true;

      this.conditions = this.conditions.sort((a, b) => (a.path > b.path) ? 1 : ((b.path > a.path) ? -1 : 0))
    },

    collapsedTree: function () {
      let labelsMap = new Map();

      this.conditions.forEach(el => labelsMap.set(el.id, el.name));

      return this.conditions
          .filter(e => e.path && !e.hasChild)
          .map(e => {

            const labels = e.path.split(":").reduce((acc, id) => {
              acc[id] = labelsMap.get(id);
              return acc;
            }, {})

            return {
              path: e.path,
              labels: labels,
              price: e.price && (e.price.from * 1 || e.price.to * 1) ? {
                from:  e.price.from ? e.price.from * 1 : null,
                to:  e.price.to ? e.price.to * 1 : null,
              } : null,
              skus: e.skus ? e.skus.split(",") : null,
              invert: e.invert
            };
          });
    },

    createTree: function (items) {
      let data = new Map();

      data.set("", this.createEmptyTreeItem(items));

      for (let item of this.createTreeItems(items))
        if (!data.has(item.path))
          data.set(item.path, item);

      return Array
          .from(data, function (entry) {
            return entry[1];
          })
          .sort((a, b) => (a.path > b.path) ? 1 : ((b.path > a.path) ? -1 : 0));
    },

    emitTree: function () {
      const data = this.collapsedTree();
      this.$emit('update:modelValue', data);
      this.$emit('update', data);
    },

    createEmptyTreeItem: function (items) {
      return {
        id: null,
        path: null,
        name: 'All',
        lvl: 0,
        price: {
          from: null,
          to: null
        },
        skus: null,
        invert: false,
        hasChild: items.length > 0,
        canEdit: true
      }
    },

    createTreeItems: function (items) {
      const data = [];

      for (let item of items) {

        const ids = item.path.split(":");
        let path = '';

        for (let index in ids) {

          const id = ids[index];

          path += path.length > 0 ? ':' + id : id;

          data.push({
            id: id,
            path: path,
            name: item.labels[id],
            lvl: index * 1 + 1,
            price: {
              from: item.price ? item.price.from : null,
              to: item.price ? item.price.to : null
            },
            skus: item.skus ? item.skus.join(",") : '',
            invert: item.invert,
            hasChild: index * 1 !== ids.length - 1,
            canEdit: id.startsWith("category"),
          });
        }
      }

      return data;
    }
  }
};
</script>

<style scoped>

.item {
  position: relative;
}


.action {
  display: none;
  position: absolute;
  right: 10px;
  top: 8px;
}

.item:hover .action {
  display: block;
}

.action button {
  float: right;
  background: none;
  border: none;
  cursor: pointer;
}

.lvl-0 {
  padding-left: 10px;
}

.lvl-1 {
  padding-left: 20px;
}

.lvl-2 {
  padding-left: 40px;
}


.lvl-3 {
  padding-left: 60px;
}

.price-block input {
  width: 100px;
  float: left;
  margin-right: 10px;
}

</style>
