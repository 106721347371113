<template>

  <modal @close="close">

    <template v-slot:header>
      Додання нової умови
    </template>
    <template v-slot:body>

      <div>
        <input type="text" class="form-control" v-model="search" @keyup="findTags" placeholder="Пошук за тегом....">
      </div>

      <div class="condition-container">
        <div class="pt-3">
          <div class="tag-item" v-if="tagSearchOptions.length > 0">
            <span class="vocabulary">Словник</span>
            <span class="label">Назва</span>
          </div>
          <div v-else >
            Результати пошуку
          </div>
          <div v-for="tag in tagSearchOptions" :key="tag" class="tag-item hover" @click="select(tag)">
            <span class="vocabulary">{{ tag.vocabulary }}</span>
            <span class="label">{{ tag.label }}

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
            </span>
          </div>
        </div>
        <div class="pt-3 ps-2">

          <div class=" fw-bold" >
            Вибрані елементи
          </div>

          <div v-if="!selectedTags || selectedTags.length <= 0">
            Нічого не додано
          </div>

          <div v-for="tag in selectedTags" :key="tag" class="tag-item hover" @click="remove(tag)">
            <span class="vocabulary">{{ tag.vocabulary }}</span>
            <span class="label">{{ tag.label }}

              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </template>
  </modal>


</template>

<script>

import Http from "../../../lib/Http";
import Modal from "../../../components/Modal.vue";
import {debounce} from "@/lib/Util";

export default {
  name: "ConditionModal",
  props: {
    modelValue: Array,
  },
  components: {
    Modal
  },
  created() {
    this.prefetchTags().then(() => {
      if(this.modelValue.length)
        this.fetchTags(this.modelValue.map(e => e.id));
    });
  },
  data() {
    return {
      selectedTags: null,
      tagSearchOptions: [],
      search: null,
    }
  },
  methods: {
    findTags: function () {
      debounce(() => {
        if (!this.search || this.search.length < 1) {
          this.tagSearchOptions = [];
          return;
        }
        Http
            .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/tag/list?search=" + this.search)
            .then((res) => {
              this.tagSearchOptions = res.content.map(tag => {
                return {
                  id: tag.id,
                  vocabulary: tag.vocabulary,
                  label: this.$filters.locale(tag.locales),
                  name: tag.name
                }
              });
            });
      }, 300)();
    },
    prefetchTags: function () {
     return  Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/tag")
          .then((res) => {
            this.selectedTags = [];
            this.tagSearchOptions = res.content.map(tag => {
              return {
                id: tag.id,
                vocabulary: tag.vocabulary,
                label: this.$filters.locale(tag.locales),
                name: tag.name
              }
            });
          });
    },
    fetchTags: function (ids) {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/tag/list?ids=" + ids.join(","))
          .then((res) => {
            this.selectedTags = res.content.map(tag => {
              return {
                id: tag.id,
                vocabulary: tag.vocabulary,
                label: this.$filters.locale(tag.locales),
                name: tag.name
              }
            });
          });
    },
    select: function (tag) {

      if (this.selectedTags.find(t => t.id === tag.id))
        return;

      this.selectedTags.push(tag);
    },
    remove: function (tag) {
      this.selectedTags = this.selectedTags.filter(t => t.id !== tag.id);
    },
    close: function () {
      this.$emit('update:modelValue', this.selectedTags);
    }
  }
}
</script>

<style scoped>
.modal {
  min-width: 80%;
  overflow: hidden;
}

.condition-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 500px;
  border-top: 1px solid #cccccc;
  margin-top: 20px;
}

.condition-container > div {
  height: 100%;
  overflow: auto;
}

.condition-container > div:first-child {
  border-right: 1px solid #cccccc;
}

.tag-item {

  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 2fr ;
  padding: 6px 5px;
}

.tag-item span{
  font-size: 13px;
}

.tag-item svg{
  display: none;
  color: red;
  float: right;
  margin-left: 10px;
  margin-right: 10px;
}

.tag-item.hover:hover {
  background-color: #efefef;
}

.tag-item.hover:hover svg{
  display: block;
}

.tag-item .label {

}

.tag-item .vocabulary {
  opacity: 0.5;
}

</style>
