<template>

  <modal class="short-modal" @close="close">

    <template v-slot:header>
      Image Edit
    </template>
    <template v-slot:body>

      <form @submit.prevent="submit">

        <div class="row ms-1 me-1 mb-3">
          <div class="col-xl-12">Image</div>
          <div class="col-lg-12">
            <span class="preview" >
                  <img v-bind:src="$filters.image(image, '180x180')" width="70" height="70" class="img-thumbnail">
              </span>
          </div>
        </div>

        <div class="row ms-2 mb-4">
          <div class="col-xl-12">Weight</div>
          <div class="col-lg-12">
            <input type="text" class="form-control" v-model="form.weight"
                   :class="{ 'is-invalid' : v$.form.weight.$error}">
            <div class="invalid-feedback" v-for="error of v$.form.weight.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>


        <div class="row ms-1 mb-4">
          <div class="col-xl-6">
            <button type="submit" class="btn  btn-primary">Save</button>
          </div>
        </div>

      </form>

    </template>
  </modal>


</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {integer} from "@vuelidate/validators";
import Modal from "../../components/Modal.vue";

export default {
  name: "ImageModal",
  components: {
     Modal
  },
  props: {
    modelValue: Object
  },
  created() {
    if (this.modelValue) {
      this.image = this.modelValue;
      this.form.weight = this.image.weight * 1;
    }
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        weight: {integer}
      }
    };
  },
  data() {
    return {
      image: null,
      form: {
        weight: null
      }
    }
  },
  methods: {

    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return;

      this.image.weight = this.form.weight * 1;

      this.$emit('update:modelValue', this.form);
    },

    close: function () {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.preview {
  float: left;
  margin-right: 10px;
  position: relative;
}

.preview svg {
  color: red;
  cursor: pointer;
  margin-left: 20px;
}
</style>
