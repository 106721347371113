import Sidebar from '../components/Sidebar.vue'
import CampaignSearch from "@/views/marketing/promo/campaign/CampaignSearch.vue";
import CampaignEdit from "@/views/marketing/promo/campaign/CampaignEdit.vue";

import CodeList from "@/views/marketing/promo/code/CodeSearch.vue";
import CodeUsesList from "@/views/marketing/promo/code/CodeUses.vue";
import CodeEdit from "@/views/marketing/promo/code/CodeEdit.vue";
import ActionSearch from "@/views/marketing/promo/action/ActionSearch.vue";
import ActionProducts from "@/views/marketing/promo/action/ActionProducts.vue";
import ActionEdit from "@/views/marketing/promo/action/ActionEdit.vue";
import LoyaltyEdit from "@/views/marketing/loyalty/Edit.vue";

export default [

    {
        path: "/marketing/promo/campaign",
        name: "Groups",
        components: {
            default: CampaignSearch,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/campaign/create",
        name: "GroupCreate",
        components: {
            default: CampaignEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/campaign/:id",
        name: "GroupEdit",
        components: {
            default: CampaignEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/code",
        name: "CodeList",
        components: {
            default: CodeList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/code/uses",
        name: "CodeUsesList",
        components: {
            default: CodeUsesList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/code/generate",
        name: "CodeCreate",
        components: {
            default: CodeEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/code/:id",
        name: "CodeEdit",
        components: {
            default: CodeEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/action",
        name: "ActionSearch",
        components: {
            default: ActionSearch,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/action/:id/products",
        name: "ActionProducts",
        components: {
            default: ActionProducts,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/action/create",
        name: "ActionCreate",
        components: {
            default: ActionEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/promo/action/:id",
        name: "ActionEdit",
        components: {
            default: ActionEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/marketing/loyalty",
        name: "LoyaltyEdit",
        components: {
            default: LoyaltyEdit,
            LeftSidebar: Sidebar
        }
    },
];
