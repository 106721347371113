<template>
  <head-panel>
    <template v-slot:body>
      <span v-if="action"> Action edit {{ action.id }}</span>
      <span v-else> Action create</span>

      <router-link :to="this.campaign ? '/marketing/promo/action?gid='+this.campaign.id:'/marketing/promo/action'">
        <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-left-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
          </svg>
          Back to list
        </button>
      </router-link>


      <a @click="remove" class="btn btn-danger btn-sm btn-remove float-end" v-if="action">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
             viewBox="0 0 16 16">
          <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        Delete
      </a>

    </template>
    <template v-slot:top>&nbsp;</template>
  </head-panel>

  <div class="wrapper ps-3 pe-3">

    <ul class="nav nav-pills" v-if="action">
      <li class="nav-item">
        <a class="nav-link" v-bind:class="tab==='main'?'active':''" aria-current="page" @click="tab='main'">Main</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" v-bind:class="tab==='conditions'?'active':''" @click="tab='conditions'">Condition</a>
      </li>
    </ul>

    <hr v-if="action"/>

    <Alert ref="alert"/>

    <div class="tab" v-show="tab==='main'">

      <form @submit.prevent="submit" v-if="IS_READY">


        <div class="row  mb-4">
          <div class="col-xl-3">Name</div>
          <div class="col-lg-8">
            <input type="text" class="form-control" v-model="form.name"
                   :class="{ 'is-invalid' : v$.form.name.$error}">
            <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-xl-3">Campaign</div>
          <div class="col-lg-8">

            <span class="group-title"
                  v-bind:class="campaignError ? 'is-invalid' : ''">{{
                campaign ? campaign.name : 'Choose campaign'
              }}</span>

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil"
                 viewBox="0 0 16 16" @click="isChooseCampaignOpen=true" v-if="!action">
              <path
                  d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
            </svg>

            <div class="invalid-feedback" v-if="campaignError">
              Choose campaign
            </div>
          </div>
        </div>

        <div class="row  mb-4">
          <div class="col-xl-3">Expiration</div>
          <div class="col-lg-2">

            <div class="input-group mb-3">

              <VueDatePicker v-model="form.expiredAt" :min-date="new Date()" :multi-calendars="false"
                             :enable-time-picker="false"></VueDatePicker>

              <div class="invalid-feedback" v-for="error of v$.form.expiredAt.$errors" :key="error.$uid">
                {{ error.$message }}
              </div>

            </div>
          </div>
          <div class="col-lg-2">
            <div class="form-check form-switch mt-2">
              <input class="form-check-input" type="checkbox" role="switch" id="stimer" v-model="form.timer">
              <label class="form-check-label" for="stimer"></label>
              <label class=" form-text" for="stimer">With timer</label>
            </div>
          </div>
        </div>

        <div class="row  mb-4">
          <div class="col-xl-3">Type</div>
          <div class="col-lg-2">

            <select class="form-select mapping-config" v-model="form.type" :disabled="action">
              <option :value="type.value" v-for="type in types" :key="type">{{ type.label }}</option>
            </select>

            <div class="invalid-feedback" v-for="error of v$.form.type.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div class="row  mb-4">
          <div class="col-xl-3">Subtype</div>
          <div class="col-lg-2">

            <select class="form-select mapping-config" v-model="form.subtype" :disabled="action">
              <option :value="type.value" v-for="type in subtypes" :key="type">{{ type.label }}</option>
            </select>

            <div class="invalid-feedback" v-for="error of v$.form.subtype.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div class="row  mb-4">
          <div class="col-xl-3"></div>
          <div class="col-lg-6">
            <delivery-features v-if="form.type === 'DELIVERY'" v-model="form.properties"/>
            <related-features v-if="form.type === 'RELATED'" v-model="form.properties"/>
          </div>
        </div>


        <div class="row  mb-4">
          <div class="col-xl-3">Description</div>
          <div class="col-lg-8">
            <editor v-model="form.description"/>
          </div>
        </div>

        <div class="row  mb-4 mt-5">
          <div class="col-xl-6">


            <!-- Example split danger button -->
            <div class="btn-group" v-if="action">
              <button type="submit" class="btn btn-primary">Save</button>
              <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                      data-bs-toggle="dropdown" aria-expanded="false">
                <span class="visually-hidden">Toggle Dropdown</span>
              </button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" @click="publish(false)">Publish</a></li>
                <li><a class="dropdown-item" @click="assign(false)">Assign</a></li>
                <li><a class="dropdown-item" @click="stop()">Stop</a></li>
              </ul>
            </div>

            <button v-else type="submit" class="btn btn-primary">Save</button>

          </div>
        </div>

      </form>

    </div>

    <div class="tab" v-show="tab==='conditions'">

      <ConditionTable v-model="conditions"/>

      <div class="row  mb-4">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary" @click.prevent="updateTree">Save conditions</button>
        </div>
      </div>

    </div>

  </div>

  <confirm-dialogue ref="confirmDialogue"/>
  <ChooseCampaignModal v-if="isChooseCampaignOpen" @close="isChooseCampaignOpen=false" v-model="campaign"/>

</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Http from "../../../../lib/Http";
import HeadPanel from "../../../../components/HeadPanel.vue";
import ConfirmDialogue from '../../../../components/ConfirmDialog.vue'
import ConditionTable from "@/views/marketing/promo/ConditionTable.vue";
import ChooseCampaignModal from "../campaign/ChooseCampaignModal.vue"
import Alert from "../../../../components/Alert.vue";
import {useVuelidate} from "@vuelidate/core";
import {maxLength, required} from "@vuelidate/validators";
import Editor from "@/components/EditorV2.vue";

import DeliveryFeatures from "@/views/marketing/promo/features/DeliveryFeatures.vue";
import RelatedFeatures from "@/views/marketing/promo/features/RelatedFeatures.vue";

export default {
  name: "ActionEdit",
  components: {
    HeadPanel,
    ConfirmDialogue,
    Alert,
    VueDatePicker,
    ConditionTable,
    ChooseCampaignModal,
    DeliveryFeatures,
    RelatedFeatures,
    Editor
  },

  created() {
    if (this.$route.params.id)
      this.fetch(this.$route.params.id);
    else
      this.IS_READY = true;
  },
  setup: () => ({
    v$: useVuelidate(),
  }),
  validations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(255)
        },
        type: {
          required
        },
        subtype: {
          required
        },
        expiredAt: {
          required
        },
        description: {},
      }
    };
  },
  watch: {
    'form.type': function () {
      this.form.subtype = 'ACTION';
    },
    campaign() {
      this.campaignError = false;
    }
  },
  computed: {
    types() {
      return [
        {value: 'SIMPLE', label: 'Проста акція'},
        {value: 'DELIVERY', label: 'Доставка'},
        {value: 'RELATED', label: 'Супутні товари'},
      ];
    },
    subtypes() {
      if (this.form.type === "RELATED")
        return [
          {value: 'ACTION', label: 'Акція'},
          {value: 'GIFT', label: 'Подарунок'},
        ];
      else if (this.form.type === "DELIVERY")
        return [
          {value: 'ACTION', label: 'Акція'},
          {value: 'DELIVERY', label: 'Безкоштовна доставка'},
        ];
      else
        return [
          {value: 'ACTION', label: 'Акція'},
          {value: 'DELIVERY', label: 'Безкоштовна доставка'},
          {value: 'GIFT', label: 'Подарунок'},
        ];
    },
  },
  data() {
    return {
      IS_READY: false,
      tab: 'main',
      action: null,
      conditions: [],
      campaign: null,
      isChooseCampaignOpen: false,
      campaignError: false,
      form: {
        name: null,
        type: "DELIVERY",
        subtype: "ACTION",
        expiredAt: new Date(),
        timer: false,
        properties: null,
        description: null,
      }
    }
  },
  methods: {

    fetch: function (id) {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/promo/action/" + id)
          .then((res) => {
            this.action = res.content;
            this.conditions = res.content.conditions;
            this.campaign = res.content.campaign;
            this.form = this.action;
          })
          .then(() => {
            this.fetchCampaign(this.action.campaignId);
            this.IS_READY = true;
          });
    },

    fetchCampaign: function (id) {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/promo/campaign/" + id)
          .then((res) => {
            this.campaign = res.content;
          });
    },

    updateTree: function () {

      if (!this.action)
        return;

      const conditions = this.conditions.map(e => {
        return {
          id: e.id,
          labels: e.labels,
          path: e.path,
          skus: e.skus && e.skus.length ? e.skus.filter(e => e !== '').map(e => e.trim()) : null,
          invert: e.invert,
          price: e.price,
        }
      });

      Http
          .post(process.env.VUE_APP_CRM_SERVICE + "/promo/action/" + this.action.id + "/condition", conditions)
          .then(() => {
            this.$refs.alert.success('Success');
          })
          .catch((e) => {
            this.$refs.alert.danger(e.message);
          });
    },

    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return;

      this.campaignError = false;
      if (!this.campaign) {
        this.campaignError = true;
        return;
      }

      const expiredAt = new Date(this.form.expiredAt);
      expiredAt.setUTCHours(23, 59, 59, 999);


      let data = {
        name: this.form.name,
        type: this.form.type,
        subtype: this.form.subtype,
        properties: this.form.properties,
        expiredAt: expiredAt,
        timer: this.form.timer,
        description: this.form.description
      };

      if (!this.action)
        Http
            .post(process.env.VUE_APP_CRM_SERVICE + "/promo/action/" + this.campaign.id, data)
            .then((res) => {
              this.fetch(res.content);
              this.$refs.alert.success('Success');
            })
            .catch((e) => {
              this.$refs.alert.danger(e.message);
            });
      else
        Http
            .put(process.env.VUE_APP_CRM_SERVICE + "/promo/action/" + this.action.id, data)
            .then(() => {
              this.$refs.alert.success('Success');
            })
            .catch((e) => {
              this.$refs.alert.danger(e.message);
            });
    },

    publish: function () {

      this.$refs.confirmDialogue.show({
        title: 'Publish',
        message: "Do you really want to publish a promotion?",
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok) {
          Http
              .post(process.env.VUE_APP_CRM_SERVICE + '/promo/action/' + this.action.id + '/publish')
              .then(() => {
                this.$refs.alert.success('Success');
              })
              .catch((e) => {
                this.$refs.alert.danger(e.message);
              });

        }

      })
    },

    assign: function () {

      this.$refs.confirmDialogue.show({
        title: 'Publish',
        message: "Do you really want to assign a promotion?",
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok) {
          Http
              .post(process.env.VUE_APP_CRM_SERVICE + '/promo/action/' + this.action.id + '/assign')
              .then(() => {
                this.$refs.alert.success('Success');
              })
              .catch((e) => {
                this.$refs.alert.danger(e.message);
              });

        }

      })
    },

    stop: function () {

      this.$refs.confirmDialogue.show({
        title: 'Stopping',
        message: "Do you really want to stop the promotion?",
        okButton: 'Yes',
        cancelButton: 'No',
      }).then((ok) => {
        if (ok) {
          Http
              .post(process.env.VUE_APP_CRM_SERVICE + "/promo/action/" + this.action.id + "/stop")
              .then(() => {
                this.$refs.alert.success('Success');
              })
              .catch((e) => {
                this.$refs.alert.danger(e.message);
              });
        }

      })
    },


    remove: function () {
      this.$refs.confirmDialogue.show({
        title: 'Delete',
        message: "Do you really want to delete a share and its associated data?",
        okButton: 'Yes',
        cancelButton: 'No',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_CRM_SERVICE + "/promo/action/" + this.action.id)
              .then(() => {
                this.$router.replace("/marketing/promo/action?gid=" + this.action.campaignId);
              });
      })
    }
  }
};
</script>

<style scoped>

.group-title {
  margin-right: 20px;
}

.bi-pencil {
  cursor: pointer;
}

.bi-pencil:hover {
  opacity: 0.5;
}

.dropdown-menu a {
  cursor: pointer;
  margin-bottom: 4px;
}
</style>
