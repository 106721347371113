import Sidebar from '../components/Sidebar.vue'
import List from "../views/product/Index"
import Edit from "../views/product/Edit"

export default [
    {
        path: "/product",
        name: "Product",
        components: {
            default: List,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/product/create",
        name: "ProductCreate",
        components: {
            default: Edit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/product/:root/:id",
        name: "ProductEdit",
        components: {
            default: Edit,
            LeftSidebar: Sidebar
        }
    }
];
