import Sidebar from '../components/Sidebar.vue'
import CatalogList from "@/views/planting/Index.vue";
import CatalogEdit from "@/views/planting/Edit.vue";

import CatalogGroupList from "@/views/planting/IndexGroup.vue";
import CatalogGroupEdit from "@/views/planting/EditGroup.vue";

import EditShortCut from "@/views/planting/EditShortCut.vue";

export default [
    {
        path: "/planting",
        name: "Catalog",
        components: {
            default: CatalogList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/planting/create",
        name: "CatalogCreate",
        components: {
            default: CatalogEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/planting/:id",
        name: "CatalogEdit",
        components: {
            default: CatalogEdit,
            LeftSidebar: Sidebar
        }
    },


    {
        path: "/planting/:catalog/list",
        name: "CatalogGroup",
        components: {
            default: CatalogGroupList,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/planting/:catalog/create",
        name: "CatalogGroupCreate",
        components: {
            default: CatalogGroupEdit,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/planting/:catalog/campaign/:campaign",
        name: "CatalogGroupEdit",
        components: {
            default: CatalogGroupEdit,
            LeftSidebar: Sidebar
        }
    },

    {
        path: "/planting/:catalog/campaign/:campaign/create",
        name: "EditShortCutCreate",
        components: {
            default: EditShortCut,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/planting/:catalog/campaign/:campaign/shortcut/:shortcut",
        name: "EditShortCutEdit",
        components: {
            default: EditShortCut,
            LeftSidebar: Sidebar
        }
    },


];
