<template>
  <div>
    <modal class="short-modal" @close="close">

      <template v-slot:header>
        Campaign
      </template>
      <template v-slot:body>

        <div class="form-group ms-3 me-3 mb-2">
          <input type="text" class="form-control form-control-sm" v-model="search" placeholder="Search">
        </div>

        <table class="table">
          <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Statue</th>

            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in items" :key="item">
            <td>{{item.name}}</td>
            <td><span v-bind:class="item.status ? 'status status-'+item.status.alias : ''">{{item.status}}</span></td>
            <td class="actions">
              <a @click="submit(item)">Choose</a>
            </td>
          </tr>
          </tbody>
        </table>

      </template>
    </modal>

  </div>
</template>

<script>
import Http from "../../../../lib/Http";
import Modal from "../../../../components/Modal.vue";
import {debounce} from "@/lib/Util";

export default {
  name: "ChooseGroupModal",
  components: {
    Modal
  },
  props: {
    modelValue: Object
  },
  created() {
      this.fetch();
  },
  watch: {
    search() {
      debounce(() => this.fetch(), 400)();
    }
  },
  data() {
    return {
      search: null,
      items: [],
    }
  },
  methods: {
    fetch: function (){

      const query = {};

      if(this.search)
        query.search = this.search.toLowerCase();

      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/promo/campaign?"+new URLSearchParams(query).toString())
          .then((res) => {
            this.items = res.content;
          });
    },
    submit: function (campaign) {
      this.$emit('update:modelValue', campaign);
      this.close();
    },
    close: function () {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>
