<template>
  <head-panel>
    <template v-slot:body>Меню каталога <router-link to="/menu/create" class="btn btn-primary btn-sm btn-create ms-2">Створити</router-link>
    </template>
  </head-panel>

  <div class="wrapper">

    <div class="filter">
      <div class="item">
        <SearchFilter
            label="Швидкий пошук"
            name="search"
            path="menu"
            v-model="search"
        />
      </div>
    </div>


    <table class="table">
      <thead>
      <tr>

        <th scope="col">Назва</th>
        <th scope="col">Url</th>
        <th scope="col">Позиція</th>
        <th scope="col">Активно</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="menu in menus" :key="menu">
        <tr class="menu">
          <td>{{ $filters.locale(menu.locales) }}</td>
          <td>{{ menu.url }}</td>
          <td>{{ menu.weight }}</td>
          <td>{{ menu.enabled ? '':'false' }}</td>
          <td class="actions">
            <router-link :to="'/menu/'+menu.name">Ред.</router-link>
            <router-link :to="'/menu/create/'+menu.name">Додати</router-link>
            <a v-if="!Object.keys(menu.child).length" @click="remove(menu)">Видалити</a>
          </td>
        </tr>
        <template v-for="campaign in menu.child" :key="group">
          <tr class="group">
            <td>{{ $filters.locale(campaign.locales) }}</td>
            <td></td>
            <td>{{ campaign.weight }}</td>
            <td>{{ campaign.enabled ? '':'false'}}</td>
            <td class="actions">
              <router-link :to="'/menu/'+group.name">Ред.</router-link>
              <router-link :to="'/menu/create/'+group.name">Додати</router-link>
              <a v-if="!group.child.length" @click="remove(group)">Видалити</a>
            </td>
          </tr>

          <tr v-for="submenu in group.child" :key="submenu" class="sub-menu">
            <td>{{ $filters.locale(submenu.locales) }}</td>
            <td>{{ submenu.url }}</td>
            <td>{{ submenu.weight }}</td>
            <td>{{ submenu.enabled ? '':'false'}}</td>
            <td class="actions">
              <router-link :to="'/menu/'+submenu.name">Ред.</router-link>
              <router-link :to="'/menu/create/'+submenu.name">Додати</router-link>
              <a @click="remove(submenu)">Видалити</a>
            </td>
          </tr>
        </template>
      </template>

      </tbody>
    </table>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import SearchFilter from "../../components/SearchFilter";
import {groupBy} from "../../lib/Util";
import ConfirmDialogue from "../../components/ConfirmDialog";


export default {
  name: "Menu",
  components: {
    HeadPanel, SearchFilter, ConfirmDialogue
  },
  created() {
    this.fetch();
  },
  computed: {
    menus: function () {

      if (!this.search)
        return this.data;

      let menus = JSON.parse(JSON.stringify(this.data)); // deep copy

      for (let menuName of Object.keys(menus)) {
        for (let groupName of Object.keys(menus[menuName].child)) {
          menus[menuName].child[groupName].child = menus[menuName].child[groupName].child && menus[menuName].child[groupName].child.length ? menus[menuName].child[groupName].child.filter(item => {
            for (let locale of Object.keys(item.locales))
              if (item.locales[locale].toLocaleLowerCase().includes(this.search.toLocaleLowerCase()))
                return true;
            return false;
          }) : [];

          if (!menus[menuName].child[groupName].child.length)
            delete menus[menuName].child[groupName];
        }
      }
      return menus;
    }
  },
  data() {
    return {
      search: null,
      data: [],
    }
  },
  methods: {
    fetch: function () {
      Http
          .get(process.env.VUE_APP_CATALOG_SERVICE + "/manage/menu")
          .then((res) => {
            let catalogMenu = res.content
                .filter(menu => menu.type === "catalog")
                .sort((a, b) => a.weight > b.weight ? 1 : -1);

            let parents = groupBy(catalogMenu, 'parent');

            let data = {};
            for (let menu of parents['null']) {
              if (!menu.parent) {
                data[menu.name] = menu;
                data[menu.name].child = {};
                if (parents[menu.name]) {
                  for (let groupMenu of parents[menu.name]) {

                    // console.log(groupMenu);

                    data[menu.name].child[groupMenu.name] = groupMenu;
                    data[menu.name].child[groupMenu.name].child = parents[groupMenu.name] ?? {};
                  }
                }
              }
            }
            this.data = data;
          });
    },
    remove: function (item) {
      this.$refs.confirmDialogue.show({
        title: 'Видалення',
        message: 'Ви дійсно хочете видалити пункт меню?',
        okButton: 'Так',
        cancelButton: 'Ні',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_CATALOG_SERVICE + "/manage/menu/" + item.name)
              .then(() => this.fetch());
      })
    }
  }
};
</script>

<style scoped>

.menu td:first-child {
  font-weight: bold;
}

.group td:first-child {
  color: #676767;
  padding-left: 40px;
  font-weight: bold;
  font-style: italic;
}

.sub-menu td:first-child {
  padding-left: 80px;
}


</style>
