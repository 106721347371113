<template>
  <head-panel>
    <template v-slot:body>Codes <span v-if="campaign">: {{ campaign.name }}</span>
      <router-link :to="campaign ? '/marketing/promo/code/generate?gid='+campaign.id : '/marketing/promo/code/generate'"
                   class="btn btn-primary btn-sm btn-create ms-2"
                   v-if="this.$session.can('owner', 'promo_code_manage')">
        Generate codes
      </router-link>
    </template>
  </head-panel>

  <div class="wrapper">

    <tab/>

    <hr/>

    <div class="filter">

      <div class="item">
        <SearchFilter
            label="Швидкий пошук"
            name="search"
            path="code"
        />
      </div>

      <div class="item">
        <DropdownFilter
            label="Статус"
            name="status"
            path="code"
            :items="this.statuses"
        />
      </div>

    </div>

    <table class="table">
      <thead>
      <tr>
        <th scope="col">Код</th>
        <th scope="col">Дисконт</th>
        <th scope="col">Тип</th>
        <th scope="col">Назва</th>
        <th scope="col">Група</th>
        <th scope="col">Статус</th>
        <th scope="col">Термін дії</th>
        <th scope="col">Створений</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" :key="item">
        <td>{{ item.code }}</td>
        <td>{{ item.discount }}</td>
        <td>{{ item.type }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.campaign.name }}</td>
        <td><span v-bind:class="item.status ? 'status status-'+item.status.alias : ''">{{ item.status }}</span></td>
        <td>{{ $filters.date(item.expiredAt) }}</td>
        <td>{{ $filters.dateTime(item.createdAt) }}</td>
        <td class="actions">
          <router-link :to="'/marketing/promo/code/'+item.id" v-if="this.$session.can('owner', 'promo_code_manage')">
            Ред.
          </router-link>
          <router-link :to="'/marketing/promo/code/uses?search='+item.code">Statistic</router-link>
        </td>
      </tr>
      </tbody>
    </table>

    <Pagination
        :current-page="pagination.page"
        :total-pages="pagination.total"/>

  </div>
</template>

<script>
import Http from "../../../../lib/Http";
import HeadPanel from "../../../../components/HeadPanel.vue";
import Pagination from "../../../../components/Pagination.vue";
import DropdownFilter from "../../../../components/DropdownFilter.vue";
import Tab from "../../Tab.vue";
import SearchFilter from "@/components/SearchFilter.vue";

export default {
  name: "PromoCodes",
  components: {
    HeadPanel, Pagination, DropdownFilter, Tab, SearchFilter
  },
  created() {
    if (this.$route.query.gid)
      this.fetchGroup(this.$route.query.gid);

    this.fetch();

  },
  watch: {
    $route(n, o) {
      if (n.path === o.path)
        this.fetch();
    }
  },
  computed: {
    statuses() {
      return [
        {value: 'ACTIVE', label: 'ACTIVE'},
        {value: 'DISABLED', label: 'DISABLED'},
      ];
    },
  },
  data() {
    return {
      campaign: null,
      items: [],
      pagination: {
        page: 0,
        total: 0
      },
    }
  },
  methods: {
    fetch: function () {

      const query = {};

      if (this.$route.query.gid)
        query.cid = this.$route.query.gid;

      if (this.$route.query.search)
        query.search = this.$route.query.search;

      if (this.$route.query.status)
        query.status = this.$route.query.status;

      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/promo/code?" + new URLSearchParams(query).toString())
          .then((res) => {
            this.items = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
          });
    },
    fetchGroup: function (id) {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/promo/campaign/" + id)
          .then((res) => {
            this.campaign = res.content;
          });
    }
  }
};
</script>

<style scoped>

td.deleted {
  color: #cccccc;
}

</style>
