<template>
  <head-panel>
    <template v-slot:body>
      <span v-if="code"> Edit code {{ code.code }}</span>
      <span v-else> generate codes</span>

      <router-link
          :to="this.campaign ? '/marketing/promo/code?gid='+this.campaign.id:'/marketing/promo/code'">
        <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-left-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
          </svg>
          Back to code list
        </button>
      </router-link>


      <a @click="remove" class="btn btn-danger btn-sm btn-remove float-end" v-if="code">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
             viewBox="0 0 16 16">
          <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
        Remove code
      </a>

    </template>
    <template v-slot:top>&nbsp;</template>
  </head-panel>

  <div class="wrapper ps-3 pe-3">

    <ul class="nav nav-pills" v-if="code">
      <li class="nav-item">
        <a class="nav-link" v-bind:class="tab==='main'?'active':''" aria-current="page" @click="tab='main'">Main</a>
      </li>
      <li class="nav-item" v-if="form.type === 'PERCENT'">
        <a class="nav-link" v-bind:class="tab==='conditions'?'active':''" @click="tab='conditions'">Condition</a>
      </li>
    </ul>

    <hr v-if="code"/>

    <Alert ref="alert"/>

    <div class="tab" v-show="tab==='main'">

      <form @submit.prevent="submit">

        <div class="row mb-4">
          <div class="col-xl-3">Campaign</div>
          <div class="col-lg-8">

            <span class="group-title"
                  v-bind:class="campaignError ? 'is-invalid' : ''">{{
                campaign ? campaign.name : 'Select campaign'
              }}</span>

            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil"
                 viewBox="0 0 16 16" @click="isChooseCampaignOpen=true" v-if="!campaign">
              <path
                  d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
            </svg>

            <div class="invalid-feedback" v-if="campaignError">
              Вкажіть групу
            </div>
          </div>
        </div>

        <div class="row  mb-4" v-if="!code">
          <div class="col-xl-3">Count</div>
          <div class="col-lg-2">

            <input type="text" class="form-control" v-model="form.count"
                   :disabled="!!(form.code && form.code.length > 0)"
                   :class="{ 'is-invalid' : v$.form.count.$error}">
            <div class="form-text">Number of codes to generate</div>
            <div class="invalid-feedback" v-for="error of v$.form.count.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>


        <div class="row  mb-4">
          <div class="col-xl-3">Код</div>
          <div class="col-lg-2">
            <input type="text" class="form-control" v-model="form.code" :disabled="code">
            <div class="form-text" v-if="!code">You can specify your own code. If you do not specify a code, it will be
              generated
              automatically. For custom codes, the maximum number is 1
            </div>
            <div class="invalid-feedback" v-for="error of v$.form.code.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div class="row  mb-4">
          <div class="col-xl-3">Назва</div>
          <div class="col-lg-8">
            <input type="text" class="form-control" v-model="form.name"
                   :class="{ 'is-invalid' : v$.form.name.$error}">
            <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>


        <div class="row  mb-4">
          <div class="col-xl-3">Статус</div>
          <div class="col-lg-2">

            <select class="form-select mapping-config" v-model="form.status">
              <option :value="status.value" v-for="status in statuses" :key="status">{{ status.label }}</option>
            </select>

            <div class="invalid-feedback" v-for="error of v$.form.status.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>

        <div class="row  mb-4">
          <div class="col-xl-3">Number of uses</div>
          <div class="col-lg-9">

            <input type="text" class="form-control uses-condition" v-model="form.maxUses" :disabled="code"
                   :class="{ 'is-invalid' : v$.form.maxUses.$error}">
            <div class="form-text">The maximum number of times this code can be used. If not specified, the code will
              not be limited to
              by the number of uses
            </div>
            <div class="invalid-feedback" v-for="error of v$.form.maxUses.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>


        <div class="row  mb-4">
          <div class="col-xl-3">Discount</div>
          <div class="col-lg-9">
            <div class="input-group mb-3 discount-condition" :class="{ 'is-invalid' : v$.form.discount.$error}">
              <input type="text" class="form-control" v-model="form.discount" :disabled="code"
                     :class="{ 'is-invalid' : v$.form.discount.$error}">

              <select class="form-select" v-model="form.type" :disabled="code">
                <option selected value="PERCENT">%</option>
                <option value="AMOUNT">amount</option>
              </select>
            </div>

            <div class="invalid-feedback" v-for="error of v$.form.discount.$errors" :key="error.$uid">
              {{ error.$message }}
            </div>
            <div class="form-text">
              % - Indicates the percentage of the amount of each (non-promotional) item in the order. Allows you to
              specify additional
              restrictions on goods<br/>
              amount - Discount for the specified amount from the order as a whole<br/>
            </div>

          </div>
        </div>

        <div class="row  mb-4">
          <div class="col-xl-3">Expiration date</div>
          <div class="col-lg-2">

            <div class="input-group mb-3">

              <VueDatePicker v-model="form.expiredAt" :min-date="new Date()" multi-calendars
                             :enable-time-picker="false"></VueDatePicker>

              <div class="invalid-feedback" v-for="error of v$.form.expiredAt.$errors" :key="error.$uid">
                {{ error.$message }}
              </div>

            </div>
          </div>
        </div>


        <div class="row  mb-4" v-if="code">
          <div class="col-xl-6">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </div>

      </form>

    </div>

    <div class="tab" v-show="tab==='conditions' || !code">

      <ConditionTable v-if="form.type === 'PERCENT'" v-model="conditions"/>

      <div class="row  mb-4" v-if="code">
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary" @click.prevent="updateTree">Save the table</button>
        </div>
      </div>

      <div class="row  mb-4" v-else>
        <div class="col-xl-6">
          <button type="submit" class="btn btn-primary" @click="submit">Generate</button>
        </div>
      </div>

    </div>

  </div>
  <ChooseCampaignModal v-if="isChooseCampaignOpen" @close="isChooseCampaignOpen=false" v-model="campaign"/>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Http from "../../../../lib/Http";
import HeadPanel from "../../../../components/HeadPanel.vue";
import ConfirmDialogue from '../../../../components/ConfirmDialog.vue'
import ConditionTable from "@/views/marketing/promo/ConditionTable.vue";
import Alert from "../../../../components/Alert.vue";
import {useVuelidate} from "@vuelidate/core";
import {maxLength, maxValue, minValue, numeric, required, requiredIf} from "@vuelidate/validators";
import ChooseCampaignModal from "@/views/marketing/promo/campaign/ChooseCampaignModal.vue";

export default {
  name: "CodeEdit",
  components: {
    HeadPanel, ConfirmDialogue, Alert, VueDatePicker, ConditionTable, ChooseCampaignModal
  },
  created() {
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id);
    }
  },
  watch: {
    campaign() {
      this.campaignError = false;
    }
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      form: {
        count: {
          required: requiredIf(!this.code),
          numeric,
          maxValue: maxValue(100)
        },
        code: {
          maxLength: maxLength(7)
        },
        name: {
          required,
          maxLength: maxLength(255)
        },
        status: {
          required
        },
        maxUses: {
          numeric
        },
        discount: {
          required,
          numeric,
          minValue: minValue(1),
          maxValue: maxValue(999999)
        },
        expiredAt: {
          required
        },
      }
    };
  },
  computed: {
    statuses() {
      return [
        {value: 'ACTIVE', label: 'ACTIVE'},
        {value: 'DISABLED', label: 'DISABLED'},
      ];
    },
  },
  data() {
    return {
      tab: 'main',
      code: null,
      conditions: [],
      campaign: null,
      isChooseCampaignOpen: false,
      campaignError: false,
      form: {
        count: 1,
        code: null,
        name: null,
        status: "ACTIVE",
        maxUses: 1,
        discount: 5,
        type: 'PERCENT',
        expiredAt: new Date(),
      }
    }
  },
  methods: {

    fetch: function (id) {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/promo/code/" + id)
          .then((res) => {
            this.code = res.content;
            this.conditions = res.content.conditions;
            this.form = this.code;
          })
          .then(() => {
            this.fetchCampaign(this.code.campaignId);
          });
    },

    fetchCampaign: function (id) {
      Http
          .get(process.env.VUE_APP_CRM_SERVICE + "/promo/campaign/" + id)
          .then((res) => {
            this.campaign = res.content;
          });
    },

    updateTree: function () {

      if (!this.code)
        return;

      const conditions = this.conditions.map(e => {
        return {
          id: e.id,
          labels: e.labels,
          path: e.path,
          skus: e.skus && e.skus.length ? e.skus.filter(e => e !== '').map(e => e.trim()) : null,
          invert: e.invert,
          price: e.price,
        }
      });

      Http
          .post(process.env.VUE_APP_CRM_SERVICE + "/promo/code/" + this.code.id + "/condition", conditions)
          .then(() => {
            this.$refs.alert.success('Successs');
          })
          .catch((e) => {
            this.$refs.alert.danger(e.message);
          });
    },

    submit: async function () {

      const result = await this.v$.$validate()

      if (!result) return;

      this.campaignError = false;

      if (!this.campaign) {
        this.campaignError = true;
        return;
      }

      let data = {
        count: this.form.code && this.form.code.length > 0 ? 1 : this.form.count,
        code: this.form.code,
        name: this.form.name,
        status: this.form.status,
        maxUses: this.form.maxUses,
        discount: this.form.discount,
        type: this.form.type,
        expiredAt: this.form.expiredAt,
        conditions: this.conditions
      };

      if (!this.code)
        this.generate(this.form.count, data);
      else
        this.update(this.code, data);
    },


    update: function (code, data) {
      Http
          .put(process.env.VUE_APP_CRM_SERVICE + "/promo/code/" + code.id, data)
          .then(() => {
            this.$refs.alert.success('Success');
          })
          .catch((e) => {
            this.$refs.alert.danger(e.message);
          });
    },

    generate: function (count, data) {

      this.$refs.confirmDialogue.show({
        title: 'Code generate',
        message: "Do you really want to create codes in the amount of: <strong>" + count + "pcs.</strong>?",
        okButton: 'Yes',
        cancelButton: 'No',
      }).then((ok) => {
        if (ok)
          Http
              .post(process.env.VUE_APP_CRM_SERVICE + "/promo/code/generate/" + this.campaign.id, data)
              .then((res) => {
                this.$router.replace("/marketing/promo/code?gid=" + this.campaign.id);
                this.fetch(res.content.id);
                this.$refs.alert.success('Success');
              })
              .catch((e) => {
                this.$refs.alert.danger(e.message);
              });
      });
    },

    remove: function () {
      this.$refs.confirmDialogue.show({
        title: 'Delete',
        message: "Do you really want to delete the code and related data?",
        okButton: 'Yes',
        cancelButton: 'No',
      }).then((ok) => {
        if (ok)
          Http
              .del(process.env.VUE_APP_CRM_SERVICE + "/promo/code/" + this.code.id)
              .then(() => {
                this.$router.replace("/marketing/promo/code?cid=" + this.campaign.id);
              });
      })
    }
  }
};
</script>

<style scoped>
.discount-condition, .uses-condition {
  max-width: 200px;
}

.group-title {
  margin-right: 20px;
}

.bi-pencil {
  cursor: pointer;
}

.bi-pencil:hover {
  opacity: 0.5;
}
</style>
