<template>
  <ul class="nav nav-pills">
    <li class="nav-item">
      <router-link class="nav-link" :to="'/marketing/promo/campaign'"  v-if="this.$session.can('owner', 'promo_code_read', 'promo_code_manage')"
                   v-bind:class="$route.path.startsWith('/marketing/promo/campaign')?'active':''">Campaign
      </router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="'/marketing/promo/code'"   v-if="this.$session.can('owner', 'promo_code_read', 'promo_code_manage')"
                   v-bind:class="$route.path === '/marketing/promo/code'?'active':''">
        Codes
      </router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="'/marketing/promo/code/uses'"  v-if="this.$session.can('owner', 'promo_code_read', 'promo_code_manage')"
                   v-bind:class="$route.path.startsWith('/marketing/promo/code/uses')?'active':''">Code usages
      </router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="'/marketing/promo/action'"  v-if="this.$session.can('owner', 'promo_code_read', 'promo_code_manage')"
                   v-bind:class="$route.path.startsWith('/marketing/promo/action')?'active':''">Actions
      </router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" :to="'/marketing/loyalty'"  v-if="this.$session.can('owner', 'loyalty_manage')"
                   v-bind:class="$route.path.startsWith('/marketing/loyalty')?'active':''">Loyalty
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Tab",
  props: {
    full: Boolean
  },
}
</script>

<style scoped>

</style>
